<template>
  <div class="container">
    <van-list v-model="listLoading" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <div v-for="(item,index) in list" :key="item.order_id" class="list-item">
        <van-panel class="panel" :title="'订单编号：'+item.serial_number" :status="item.status_name">
          <template slot="default">
            <router-link :to="'/order-detail?order_id='+item.order_id">
              <van-card
                :num="item.order_details[0].product_count"
                :price="item.order_details[0].product_discount_price"
                :origin-price="item.order_details[0].original_amount"
                :desc="item.order_details[0].product_spec"
                :title="item.order_details[0].product_name"
                :thumb="item.order_details[0].product_image"
              />
              <div v-if="item.order_details.length>1" class="card-load-more van-hairline--bottom">查看全部{{ item.order_details.length }}件商品</div>
            </router-link>
            <div class="panel-money">共{{ item.order_details.length }}件商品 合计：
              <span class="fz12">￥</span>
              <span class="ui-c-red fz16">{{ item.amount }}</span>
            </div>
          </template>
          <div slot="footer" class="panel-actions">
            <div class="panel-button panel-button-danger" @click="onItemClick(index)">退款</div>
          </div>
        </van-panel>
      </div>
    </van-list>
    <!-- 退款弹层 -->
    <van-popup v-model="refundVisible" position="bottom">
      <div class="popup">
        <van-icon name="close" class="popup-close" @click="onShowPopup" />
        <div class="popup-body">
          <div class="popup-title">申请退款</div>
          <!-- <van-cell title="请选择您的评分" :border="false" /> -->
          <van-field
            v-model="amount"
            type="number"
            label="可退款金额"
          />
          <van-field
            v-model="remark"
            label="售后说明"
            type="textarea"
            placeholder="请输入退款说明"
            rows="2"
            autosize
          />
        </div>
        <div class="popup-bottom">
          <van-button class="popup-confirm" type="primary" size="large" @click="onSubmitRefund">提交退款</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Card, Panel, List, Field } from 'vant'
import { isEmpty } from '@/common/validate'
import PageMixin from '@/mixins/page'

export default {
  name: 'OrederRefund',
  components: {
    [Card.name]: Card,
    [Panel.name]: Panel,
    [List.name]: List,
    [Field.name]: Field
  },
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1,
      refundVisible: false,
      orderId: '',
      amount: 0,
      remark: ''
    }
  },
  created() {

  },
  methods: {
    onListLoad() {
      this.$api.order_index({ 'per-page': this.pageSize, page: this.pageCurrent, status: 'canceled' }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    },
    onItemClick(index) {
      this.amount = Number(this.list[index].order_amount)
      this.orderId = this.list[index].order_id
      this.onShowPopup()
    },
    onShowPopup() {
      this.refundVisible = !this.refundVisible
    },
    onSubmitRefund() {
      const params = {
        order_id: this.orderId,
        refund_amount: this.amount,
        refund_note: this.remark
      }
      if (isEmpty(this.remark)) {
        this.$toast('请输入退款说明')
        return
      }
      this.$toast.loading({
        mask: true,
        message: '提交中...',
        duration: 0
      })
      this.$api.order_refund(params)
        .then(res => {
          this.$toast.success('提交退款成功')
          this.onShowPopup()
        })
        .catch(err => {
          this.$toast.fail('提交退款失败')
          console.error(err)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.list-item {
  margin-bottom: 10px;
}
.card-load-more{
  font-size: 14px;
  color:#f44;
  padding:5px;
  line-height:28px;
  text-align:center;
}
.panel {
  .van-panel__header-value {
    flex: none;
  }
  &-money {
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    text-align: right;
  }
  &-actions {
    text-align: right;
  }
  &-button {
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 20px;
    color: #666;
    border: 1px solid #b9b9b9;
  }
  &-button-danger {
    color: #f44;
    border: 1px solid #f44;
  }
  &-button::active {
    opacity: 0.8;
  }
}
.no-data .van-loading__spinner {
  display: none;
}

.popup {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 300px;
  &-close {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 20;
    color: #969799;
    font-size: 20px;
    text-align: center;
  }
  &-body {
    width: 100%;
    flex: 1;
  }
  &-title {
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
  }
  &-bottom {
    width: 100%;
  }
  .rate-box {
    padding: 0px 15px;
    margin-bottom: 30px;
  }
}
</style>

